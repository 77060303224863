import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare let gtag: (action: string, event: string, data: unknown) => void;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  platformId = inject(PLATFORM_ID);

  event(event: string, data: unknown) {
    if (isPlatformBrowser(this.platformId)) {
      if (gtag) {
        gtag('event', event, data);
      }
    }
  }
}
