import { ChangeDetectionStrategy, Component } from '@angular/core';
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

@Component({
    selector: 'app-spinner',
    imports: [
        FaIconComponent
    ],
    templateUrl: './spinner.component.html',
    styleUrl: './spinner.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  protected readonly faSpinnerThird = faSpinnerThird;
}
