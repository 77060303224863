<button
  (click)="executeAction()"
  class="flex w-full justify-center items-center space-x-3 {{
    cssClass()
  }} rounded-md text-sm px-8 leading-6 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
  [ngClass]="{
    'py-3': size() === 'medium',
    'py-1.5': size() === 'small',
    'text-primary-orange-contract': color() === 'orange',
    'text-white': color() === 'green',
    'bg-gray-300 text-gray-800 hover:bg-gray-400': disabled(),
    'bg-primary hover:bg-primary-light focus:bg-primary':
      !disabled() && color() === 'green',
    'bg-primary-orange hover:bg-primary-orange/90':
      !disabled() && color() === 'orange',
  }"
>
  @if (loading()) {
    <app-spinner class="text-white"></app-spinner>
  }

  <div>
    <ng-content></ng-content>
  </div>
</button>
